import { Controller } from '@hotwired/stimulus';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default class extends Controller {
  static values = {
    url: String
  };

  static targets = ['outlet'];

  connect () {
    this.chart = null;
    fetch(this.urlValue)
      .then(response => response.json())
      .then((config) => {
        this.chart = new Chart(this.outletTarget, config); // eslint-disable-line no-new
      });
  }

  disconnect() {
    if (this.chart != null) {
      this.chart.destroy();
    }
  }
}
